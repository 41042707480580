import { BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button/Button';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import usePropertiesCreationAllowed from 'components/domain/property/usePropertiesCreationAllowed';
import FormWithProvider from 'components/forms/form/Form';
import Loader from 'components/loader/Loader';
import PageHeader from 'components/pageHeader/PageHeader';
import { PropertyPageBreadcrumb } from '../../common/Common.styles';
import NotFound from '../../../notFound/NotFound';
import {
  addSinglePropertySchema,
  getDefaultFormValues,
} from './AddSingleProperty.schema';
import useAddSinglePropertySubmit from './useAddSinglePropertySubmit';
import AddSinglePropertyFormElements from './AddSinglePropertyFormElements';

const AddSingleProperty = () => {
  const { data: propertyTypes, isLoading: isLoadingPropertyTypes } =
    useFetchPropertyTypes();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = useAddSinglePropertySubmit();

  const propertiesCreationAllowed = usePropertiesCreationAllowed();

  if (isLoadingPropertyTypes) {
    return <Loader />; // TODO replace with loading placeholder?
  }

  if (!propertiesCreationAllowed) {
    return <NotFound />;
  }

  const pageTitle = t('common.pageTitles.property/add/single');

  return (
    <div className="container">
      <PropertyPageBreadcrumb>
        <BreadcrumbItem href="#/properties">
          {t('common.pageTitles.properties')}
        </BreadcrumbItem>
        <BreadcrumbItem active>{pageTitle}</BreadcrumbItem>
      </PropertyPageBreadcrumb>
      <PageHeader
        title={pageTitle}
        subtitle={t('pageProperty.add.single.subtitle')}
        buttons={
          <Button
            data-testid="airbnb-import-button"
            bsStyle="success"
            onClick={() => navigate('/property/airbnb-import')}
          >
            {t('pageProperty.add.single.airbnbImport')}
          </Button>
        }
        noDivider
      />
      <FormWithProvider
        defaultValues={getDefaultFormValues(propertyTypes)}
        resolver={addSinglePropertySchema(t)}
        onSubmit={onSubmit}
        noValidate
      >
        <AddSinglePropertyFormElements />
      </FormWithProvider>
    </div>
  );
};

export default AddSingleProperty;
