import { Lead } from 'models/Leads';
import { Template } from 'models/Templates';

const useTemplateModalFormInitialValue = () => {
  return ({
    lead,
    template,
    to,
    sendCopyToAgency,
  }: {
    lead?: Lead;
    template: Template;
    to: string[];
    sendCopyToAgency: boolean;
  }) => {
    const defaultLocale = 'en';

    const localeToUse =
      lead?.preferredLocale &&
      template.contents.find(
        (localeTemplate) => localeTemplate.locale === lead.preferredLocale,
      )
        ? lead.preferredLocale
        : defaultLocale;

    const localeTemplate = template.contents.find(
      (content) => content.locale === localeToUse,
    );

    return {
      leadUid: lead?.uid,
      subject: localeTemplate.title,
      content: localeTemplate.content,
      to: to[0],
      sendCopyToAgency,
    };
  };
};

export default useTemplateModalFormInitialValue;
