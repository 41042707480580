import { ReactNode } from 'react';
import { TableProps } from 'components/table/Table.types';
import {
  CommonTableCaption,
  CommonTableWrapperStyled,
} from './CommonTable.styles';
import Table from './Table';

interface CommonTableProps<T> extends TableProps<T> {
  caption?: ReactNode;
}

const CommonTable = <T,>({
  caption,
  isLoading,
  tableInstance,
  ...tableProps
}: CommonTableProps<T>) => {
  const isEmpty = !tableInstance.options.data.length;
  const isNotEmpty = !isEmpty;
  const hasManyPages = tableInstance.getPageCount() > 1;

  return (
    <CommonTableWrapperStyled $isEmpty={isEmpty} $isLoading={isLoading}>
      <Table
        isLoading={isLoading}
        tableInstance={tableInstance}
        {...tableProps}
      >
        {caption && (
          <Table.Caption>
            <CommonTableCaption>{caption}</CommonTableCaption>
          </Table.Caption>
        )}

        {isNotEmpty && <Table.ColGroup />}
        {isNotEmpty && <Table.Header />}
        <Table.Body />

        {isNotEmpty && hasManyPages && (
          <Table.SingleCellFooter>
            <Table.Pagination />
          </Table.SingleCellFooter>
        )}
      </Table>
    </CommonTableWrapperStyled>
  );
};

CommonTable.defaultProps = {
  caption: undefined,
};

export default CommonTable;
