import { useTranslation } from 'react-i18next';
import CopyrightNotice from 'components/text/CopyrightNotice';
import { Container, Link } from './Footer.styles';
import useIntercomUserVoice from './useIntercomUserVoice';

const AgencyFooter = () => {
  const { t } = useTranslation();
  const { userVoiceProperties } = useIntercomUserVoice();

  return (
    <Container>
      <div className="copyright">
        <CopyrightNotice />
      </div>
      <div className="links">
        <Link
          data-testid="link-terms-of-service"
          href="https://platform.hostfully.com/static/tos.jsp"
        >
          {t('common.layout.termsOfService')}
        </Link>
        {' | '}
        <Link
          data-testid="link-privacy-policy"
          href="https://platform.hostfully.com/static/privacy.jsp"
        >
          {t('common.layout.privacyPolicy')}
        </Link>
        {' | '}
        <Link data-testid="link-feedback" {...userVoiceProperties}>
          {t('common.layout.feedback')}
        </Link>
      </div>
    </Container>
  );
};

export default AgencyFooter;
