import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import { PaginationStyled } from 'components/table/TablePagination.styles';
import { SingleCellFooterStyled } from 'components/table/footer/SingleCellFooter.styles';
import { BSTableStyled } from './Table.styles';

export const CommonTableCaption = styled.span``;

export const CommonTableWrapperStyled = styled.div<{
  $isLoading: boolean;
  $isEmpty: boolean;
}>`
  max-width: 100%;
  overflow: auto;

  ${BSTableStyled} {
    border-collapse: separate;
    table-layout: fixed;

    .column-header .column-header-text,
    .body-cell:not(.body-cell-actions) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .column-header-actions {
      width: 130px;
    }

    ${SingleCellFooterStyled} {
      text-align: right;

      td {
        padding-right: 0;
      }
    }

    ${({ $isLoading }) =>
      !$isLoading &&
      css`
        .body-cell-actions {
          padding: 0;
        }
      `}

    ${mediaQuery.xs} {
      ${({ $isEmpty }) => !$isEmpty && 'min-width: 768px;'}

      ${({ $isLoading }) =>
        !$isLoading &&
        css`
          .column-header-actions,
          .body-cell-actions {
            background-color: white;
            border-left: 1px solid #aeb5bc;
          }

          .column-header-actions,
          .body-cell-actions,
          ${PaginationStyled} {
            position: sticky;
            right: 0;
          }

          ${CommonTableCaption} {
            position: sticky;
            left: 0;
          }
        `}
    }
  }
`;
